<template lang="html"> 
  <div class="">
    <div class="">
      <div class="">
        <vx-card>  
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Nombre(s)</span>
              <input class="vs-input" v-validate="'required|alpha_spaces'" v-model="nombre" v-on:keypress="soloLetras($event)" name="nombres" />
              <span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Paterno</span>
              <input class="vs-input" v-validate="'required|alpha_spaces'" v-model="apellidoPaterno" v-on:keypress="soloLetras($event)" name="apellidoPaterno" />
              <span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Apellido Materno</span>
              <input class="vs-input" v-validate="'required|alpha_spaces'" v-model="apellidoMaterno" v-on:keypress="soloLetras($event)" name="apellidoMaterno" />
              <span class="text-danger span-placeholder" v-show="errors.has('apellidoMaterno')">Campo requerido</span>
            </div>
          </div>
          <div class="row w-full">
                      <div class="col-md-4">
                      <div class="form-group">
                      <span class="formGroupExampleInput span-placeholder">Código país</span>
                      <select name="cars" id="codigo" v-model="codigoSeleccionado" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" aria-placeholder="Seleccionar una opción" @change="actualizaCodigo()">
                            <option :value='tipo.Lada' v-for="tipo in codigoPaises"  :key="tipo.Id">{{tipo.Pais}}</option>
                        </select>
                      </div>  
            </div>
            <div class="col-md-4">
                      <span class="formGroupExampleInput span-placeholder">Teléfono celular</span>
                    <div class="input-group ">
                      <div class="input-group-prepend">
                        <span class="input-group-text " id="basic-addon1">{{codigoSeleccionado}}</span>
                      </div>
                      <input type="text" class="vs-input"  v-model="telefono" minlength="10" maxlength="10"  placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="isNumber($event)" >
                    </div>
            </div>  

             <div class="col-md-4">
              <span class="span-placeholder">Correo electronico</span>
              <input class="vs-input" v-model="email" name="email"/>
             
            </div> 

          </div>
          <div class="row w-full">

            <div class="col-md-4">
              <span class="span-placeholder">CURP</span>
              <input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"/>
              <span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
            </div>  
           
            <div class="col-md-4">
              <span class="span-placeholder">RFC</span>
              <input class="vs-input" v-model="rfc" name="rfc"  v-validate="'min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
              <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
            </div>

            <div class="col-md-4">
                <span class="span-placeholder" for="sexo">Sexo</span>
                <div class="col-md-4 alinear">
                <div class="col-md-6 mr-4">
                  <span class="span-placeholder">Hombre</span>                
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="H"></vs-radio>
                </div>
                <div class="col-md-6 ml-4">
                  <span class="span-placeholder">Mujer</span>
                  <vs-radio v-model="sexo" vs-name="sexo" vs-value="M">Mujer</vs-radio>
                </div>
                </div>
            </div>

          </div>

          <div class="row w-full">
          <div class="col-md-3">
              <span class="span-placeholder">Fecha de nacimiento</span>
              <input class="vs-input" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
              <span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
            </div>
          </div>


          <br>
          <br>

          <button class="btn btn-second" @click="agregaConyuge()" :disabled="!validate">Guardar información</button>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components:{
    'v-select': vSelect,
  },
  data(){
		return{
       maskRfc: ['AAAA999999&&&'],
      conyuge:{
        participaCredito:false,
        email:'',
        nombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        curp:'',
        rfc:'',
        esConyugue:true,
        seraCoPropietario:false,
        consolidaIngresos:false,
        parentesco:1,
      },
      coacreditado:{
        email:'',
        email2:'',
        email3:'',//existe coacreditado
        esConyugue:false,
        seraCoPropietario:true,
        consolidaIngresos:true,
        parentesco:'',
        participaCredito:true,
      },
      id:'',
      key:'',
      email:'',
      nombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      fechaNacimiento:'',
      ingresosSinImpuestos:'',
      IngresosConImpuestos:'',
      estadoCivil:'',
      regimenMatrimonial:'',
      estadosCiviles:[],
      rfc:'',
      curp:'',
      sexo:'',
      calle:'',
      numeroInterior:'',
      numeroExterior:'',
      colonia:'',
      municipio:'',
      ciudad:'',
      nacionalidad:'',
      estado:'',
      codigoPostal:'',
      estados:[],
      states: ['AGUASCALIENTES','BAJA CALIFORNIA NORTE','BAJA CALIFORNIA SUR','CAMPECHE',
      'CHIAPAS','CHIHUAHUA','COAHUILA','COLIMA','CIUDAD DE MEXICO','DURANGO','ESTADO DE MÉXICO',
      'GUANAJUATO','GUERRERO','HIDALGO','JALISCO','MICHOACAN','MORELOS','NAYARIT','NUEVO LEON',
      'OAXACA','PUEBLA','QUERETARO','QUINTANA ROO','SAN LUIS POTOSI','SINALOA','SONORA','TABASCO',
      'TAMAULIPAS','TLAXCALA','VERACRUZ','YUCATAN','ZACATECAS'],
      parentescos:[],
      regimenes:[],
      nacionalidades:[
        { label: 'MEXICANA',  value: 'MEXICANO' },
        { label: 'EXTRANJERA',  value: 'EXTRANJERO' }
      ],
      codigoSeleccionado:'',
      codigoPaises:[],
    }
  },
  computed:{
    validate: function () {
      if (this.estadoCivil == 1 || this.estadoCivil==4) {
        return this.conyuge.email!='' && this.conyuge.nombre!='' &&
        this.conyuge.apellidoPaterno!='' && this.conyuge.apellidoMaterno!=''
      }else {
        return true
      }
    },
    validate2: function () {
      return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
    }
  },
  watch:{
    estadoCivil:function(){
      if (this.estadoCivil == 2 || this.estadoCivil== 3 || this.estadoCivil== 5 || this.estadoCivil== 6) {
        this.regimenMatrimonial=''
        this.conyuge.participaCredito=false
        this.conyuge.email=''
        this.conyuge.nombre=''
        this.conyuge.apellidoPaterno=''
        this.conyuge.apellidoMaterno=''
        this.conyuge.curp=''
        this.conyuge.rfc=''
        this.conyuge.esConyugue=true
        this.conyuge.seraCoPropietario=true
        this.conyuge.consolidaIngresos=true
        this.conyuge.parentesco=1
        this.conyuge.fechaNacimiento=''
        this.conyuge.sexo=''
        this.conyuge.nacionalidad=''
        this.conyuge.paisRecidencia=''
      }
    }
  },
  mounted(){

    this.getApikey()
    this.getEstados()
    this.getEstadosCiviles()
    this.getListaRegimenes()
    this.getListaParentescos()
    this.getListaCodigoPaises() //sms
  },
  methods:{

    soloLetras: function(event) {
        var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
          }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
     actualizaCodigo(){
      this.codigoPais=this.codigoSeleccionado
    },
    getListaCodigoPaises(){
      var objRequestListaPais = {
				strApiKey: this.key,
				strMetodo: 'ListaPaisLada',
			}
			this.$axios.post('/',objRequestListaPais,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.codigoPaises=response.data.objContenido
            //console.log(this.codigoPaises)
					}
				}
			).catch(function (error) {
        this.makeToast(error)
			})
    },
    dameCoacreditadoRegistrado(){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.coacreditado.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.coacreditado.email3=this.coacreditado.email
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraCoacreditado(){
      let self= this
      if (this.coacreditado.email == this.conyuge.email) {
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: '',
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.agregarCoacreditado()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else if(this.coacreditado.email3!=''){
        this.agregarCoacreditado()
      }else {
        if (this.coacreditado.email2) {
          this.agregarCoacreditado()
        }else {
          let objRequestRegistraSolicitante = {
            strApiKey: this.key,
            strMetodo: 'RegistraSolicitante',
            objSolicitante: {
              EMail: this.coacreditado.email,
              Nombres: '',
              ApellidoPaterno: '',
              ApellidoMaterno: '',
              TelefonoCelular: '',
              FechaNacimiento: '',
            }
          }
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.agregarCoacreditado()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        }
      }
    },
    agregarCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.coacreditado.email,
          DatosCoacreditado:{
            EsConyuge:this.coacreditado.esConyugue,
            SeraCoPropietario:this.coacreditado.seraCoPropietario,
            ConsolidaIngresos:this.coacreditado.consolidaIngresos,
            Parentesco:this.coacreditado.parentesco,
            ParticipaCredito:this.coacreditado.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyugeAsCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.conyuge.email,
          DatosCoacreditado:{
            EsConyuge:this.conyuge.esConyugue,
            SeraCoPropietario:this.conyuge.seraCoPropietario,
            ConsolidaIngresos:this.conyuge.consolidaIngresos,
            Parentesco:this.conyuge.parentesco,
            ParticipaCredito:this.conyuge.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyuge(){
      if (this.conyuge.email != '') {
        let self= this

         let sexoConyuge
          if(this.conyuge.sexo== 'H'){
            sexoConyuge=1
          }else{
            sexoConyuge=2
          }


        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: this.conyuge.fechaNacimiento,
            Rfc:this.conyuge.rfc,
            Curp:this.conyuge.curp,
            Sexo:sexoConyuge ,
            Nacionalidad: this.conyuge.nacionalidad,
            PaisRecidencia: this.conyuge.paisRecidencia

          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              if (this.conyuge.participaCredito) {
                this.agregaConyugeAsCoacreditado()
              }
              this.actualizarInfo()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else {
        this.actualizarInfo()
      }
    },
    actualizarInfo(){
      let self= this
      let sexo
      if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.nombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          Lada: this.codigoSeleccionado, //agregar sms
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
      		Sexo:sexo,
      		Calle:this.calle,
      		NumeroExterior:this.numeroExterior,
          NumeroInterior:this.numeroInterior,
      		Colonia:this.colonia,
      		Municipio:this.municipio,
      		Ciudad:this.ciudad,
      		Estado:this.estado,
      		CodigoPostal:this.codigoPostal,
          Nacionalidad:this.nacionalidad,
          EstadoCivil:this.estadoCivil,
          RegimenMatrimonial:this.regimenMatrimonial,
          Conyuge: this.conyuge.email,
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.$vs.notify({
                  title:`Datos guardados exitosamente`,
                  position:'top-right'
                })
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            let coacreditado=response.data.objContenido.Coacreditado
            if (coacreditado.length) {
              if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
                this.conyuge.esConyugue=coacreditado[0].EsConyuge
                this.conyuge.email=coacreditado[0].EMail
                this.conyuge.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.conyuge.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.conyuge.parentesco=coacreditado[0].Parentesco
                this.conyuge.participaCredito=coacreditado[0].ParticipaCredito
              }else {
                this.coacreditado.esConyugue=coacreditado[0].EsConyuge
                this.coacreditado.email2=coacreditado[0].EMail
                this.coacreditado.email=coacreditado[0].EMail
                this.coacreditado.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.coacreditado.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.coacreditado.parentesco=coacreditado[0].Parentesco
                this.coacreditado.participaCredito=coacreditado[0].ParticipaCredito
              }
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(email){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let conyuge =response.data.objContenido
            this.conyuge.nombre= conyuge.Nombres
            this.conyuge.email= conyuge.EMail
            this.conyuge.apellidoPaterno= conyuge.ApellidoPaterno
            this.conyuge.apellidoMaterno= conyuge.ApellidoMaterno
            this.conyuge.rfc=conyuge.Rfc
            this.conyuge.curp = conyuge.Curp
            this.conyuge.fechaNacimiento= conyuge.FechaNacimiento
            if(conyuge.Sexo== 1){
              this.conyuge.sexo='H'
            }else if (conyuge.Sexo== 2) {
              this.conyuge.sexo='M'
            }
            this.conyuge.nacionalidad= conyuge.Nacionalidad
            this.conyuge.paisRecidencia= conyuge.PaisRecidencia

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.nombre= solicitante.Nombres
            this.apellidoPaterno= solicitante.ApellidoPaterno
            this.apellidoMaterno= solicitante.ApellidoMaterno
            this.telefono= solicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
            this.fechaNacimiento= solicitante.FechaNacimiento
            this.rfc=solicitante.Rfc
            if(solicitante.Sexo== 1){
              this.sexo='H'
            }else if (solicitante.Sexo== 2) {
              this.sexo='M'
            }
            this.calle = solicitante.Calle
            this.numeroExterior = solicitante.NumeroExterior
            this.numeroInterior = solicitante.NumeroInterior
            this.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.municipio = solicitante.Municipio
            this.curp = solicitante.Curp
            this.codigoPostal = solicitante.CodigoPostal
            this.estado = solicitante.Estado
            this.estadoCivil=solicitante.EstadoCivil
            this.regimenMatrimonial=solicitante.RegimenMatrimonial
            this.nacionalidad=solicitante.Nacionalidad
            if (solicitante.Conyuge != null && (this.estadoCivil== 1 || this.estadoCivil== 4)) {
              this.dameConyuge(solicitante.Conyuge)
            }
            this.codigoSeleccionado=solicitante.Lada
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getStateName(id){
      return this.states[id-1]
    },
    getStateNumber(stateName){
      for (var i = 0; i < this.states.length; i++) {
        if(stateName == this.states[i]){
          return i+1
        }
      }
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaRegimenes(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaRegimenesMatrimoniales',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.regimenes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaRegimenesMatrimoniales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="css">
.red{
  color: red;
}
.input-group{
flex-wrap: nowrap;
}
.input-group-text{
  margin-top: 10px;
  height: 40px;
}
</style>